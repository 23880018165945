import React from 'react';
import { IconType, RotateType } from '../types';

const Cross = (props: IconType & RotateType) => {
  const {
    primaryColor = '1F1F22',
    width = Number(14),
    height = Number(14),
    rotate = 45
  } = props;
  const viewBox =
    width > 14 && height > 14 ? () => `0 0 ${width} ${height}` : () => `0 0 14 14`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox()}
      fill="none"
      style={{ transform: `rotate(${rotate}deg)`, transition: 'transform 0.3s' }}
    >
      <path
        d="M21.0977 21.0967L25.569 21.0967C25.86 21.097 26.1393 20.9816 26.3452 20.776C26.5512 20.5704 26.6671 20.2915 26.6673 20.0005C26.6676 19.7095 26.5522 19.4303 26.3466 19.2243C26.141 19.0184 25.862 18.9025 25.5709 18.9023H21.0996L21.0996 14.4322C21.0996 14.1409 20.9839 13.8616 20.7779 13.6556C20.572 13.4497 20.2926 13.334 20.0013 13.334C19.71 13.334 19.4306 13.4497 19.2247 13.6556C19.0187 13.8616 18.903 14.1409 18.903 14.4322L18.903 18.9023L14.4323 18.9023C14.141 18.9023 13.8616 19.018 13.6557 19.2239C13.4497 19.4299 13.334 19.7092 13.334 20.0005C13.334 20.2917 13.4497 20.5711 13.6557 20.777C13.8616 20.983 14.141 21.0987 14.4323 21.0987L18.903 21.0987V25.5691C18.903 25.8604 19.0187 26.1397 19.2247 26.3457C19.4306 26.5516 19.71 26.6673 20.0013 26.6673C20.2926 26.6673 20.572 26.5516 20.7779 26.3457C20.9839 26.1397 21.0996 25.8604 21.0996 25.5691L21.0977 21.0967Z"
        fill={primaryColor} />
    </svg>
  );
};

export default Cross;
